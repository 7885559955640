import request from '@/utils/request'
// banner图
export function bannerList(params) {
  return request({
    url: '/main/getBannerList',
    method: 'post',
    params
  })
}
// 专家团队
export function getTeamList(data) {
  return request({
    url: "/main/getTeamList",
    method: "post",
    data,
  });
}
// 创始人
export function getFounder(data) {
  return request({
    url: "/main/getTeamFrom",
    method: "post",
    data,
  });
}
// 新闻列表--首页
export function getNewsList(data) {
  return request({
    url: "/main/getNewsList",
    method: "post",
    data,
  });
}
// 公司信息
export function getGsForm(data) {
  return request({
    url: "/main/getGsForm",
    method: "post",
    data,
  });
}
// 读本
export function getBookList(data) {
  return request({
    url: "/main/getBookList",
    method: "post",
    data,
  });
}
// 果盛师资
export function getTeacherList(data) {
  return request({
    url: "/main/getTeacherList",
    method: "post",
    data,
  });
}
// 学校
export function getSchoolList(data) {
  return request({
    url: "/main/getSchoolList",
    method: "post",
    data,
  });
}
// 新闻列表--自己
export function getOursList(data) {
  return request({
    url: "/main/getOursList",
    method: "post",
    data,
  });
}
//新闻详情
export function getNewsDetail(params) {
  return request({
    url: "/main/getOursForm",
    method: "post",
    params,
  });
}
//新闻置顶
export function getNewsDetailOne(params) {
  return request({
    url: "/main/getOursListOne",
    method: "post",
    params,
  });
}
// 新闻列表--别原创
export function getOursNews(data) {
  return request({
    url: "/main/getOursNews",
    method: "post",
    data,
  });
}
// 发展历程
export function getDevelopmentList(data) {
  return request({
    url: "/main/getDevelopmentList",
    method: "post",
    data,
  });
}
// 加入果盛
export function getEnter(data) {
  return request({
    url: "/main/getEnterData",
    method: "post",
    data,
  });
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/base.less'
import '@/utils/vant'
/* import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css' */
import 'swiper/dist/css/swiper.min.css'

Vue.config.productionTip = false
/* Vue.use(VueAwesomeSwiper) */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// 在应用加载完成后，滚动到页面顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});
<template>
  <div>
    <div id="Header">
      <img class="logo" src="../assets/images/index/logo.png" alt="" />
      <img
        class="left"
        @click="open()"
        src="../assets/images/index/selected.png"
        alt=""
      />
    </div>
    <div class="classIfy">
      <ul class="navbar">
        <li><router-link to="/" exact>首页</router-link></li>
        <li><router-link to="/product">产品中心</router-link></li>
        <li>
          <router-link to="/classcenter">课程中心</router-link>
        </li>
        <li>
          <router-link to="/curriculumpilot">中医药文化课程</router-link>
        </li>
        <li>
          <router-link to="/classroom">中医药文化教室</router-link>
        </li>
        <li><router-link to="/news/">新闻资讯</router-link></li>
        <li><router-link to="/about">关于果盛</router-link></li>
        <li><router-link to="/strategy">战略合作</router-link></li>
        <li><router-link to="/join">加入我们</router-link></li>
      </ul>
      <div class="close" @click="open()">
        <img src="../assets/images/index/clon.png" alt="" />
      </div>
    </div>
    <div class="ZW"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flags: "",
      deviceType: "",
    };
  },
  watch: {
    "$route.path": function () {
      this.flags = false;
      const targetElement1 = document.querySelector(".classIfy");
      // targetElement1.classList.add('active');
      if (this.deviceType === "手机") {
        targetElement1.style.marginRight = "-800px";
      } else if (this.deviceType === "平板") {
        targetElement1.style.marginRight = "-1080px";
      }
    },
  },
  created() {
    this.detectDeviceType();
  },
  methods: {
    detectDeviceType() {
      let width =
        window.innerWidth ||
        (document.documentElement ? document.documentElement.clientWidth : 0) ||
        (document.body ? document.body.clientWidth : 0) ||
        0;
      let deviceType;

      if (width < 768) {
        deviceType = "手机";
      } else if (width >= 768 && width <= 1024) {
        deviceType = "平板";
      }
      this.deviceType = deviceType;
    },
    open() {
      if (this.flags) {
        this.flags = false;
        const targetElement2 = document.querySelector(".classIfy");
        targetElement2.classList.add("active");
        if (this.deviceType === "手机") {
          targetElement2.style.marginRight = "-800px";
        } else if (this.deviceType === "平板") {
          targetElement2.style.marginRight = "-1080px";
        }
      } else {
        this.flags = true;
        document.querySelector(".classIfy").style.marginRight = "0";
      }
    },
  },
};
</script>

<style scoped lang="less">
.router-link-active {
  color: #c89769 !important;
  font-weight: bold;
}
#Header {
  height: 80px;
  width: 750px;
  z-index: 10000;
  position: fixed;
  top: 0;
  padding: 0 30px;
  background-color: #c9925e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 169px;
    /*     height: 42px; */
  }
  .left {
    width: 43px;
    /* height: 43px; */
  }
}
.classIfy {
  /* width: 500px; */
  position: fixed;
  transition: all 1s;
  top: 80px;
  right: 0px;
  background: url("../assets/images/index/showBG.png") no-repeat;
  background-size: 100% 100%;
  margin-right: -1050px;
  z-index: 10000;
  .navbar {
    padding: 0px 66px 0px 55px;
    max-height: 90vh;
    overflow-y: scroll;
    margin: 60px 0 20px;
    /* margin: 20px 0 127px 97px; */
  }
  li {
    white-space: nowrap;
    padding: 24px 0;
    line-height: 1.2;
    border-bottom: 4px solid rgba(224, 176, 110, 0.3);
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 400;
    a {
      display: inline-block;
      width: 100%;
    }
  }
  a {
    color: white;
    padding-left: 8px;
    opacity: 1;
  }
  .close {
    position: absolute;
    left: 27px;
    top: 27px;
    img {
      width: 31px;
      height: 30px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
.ZW {
  height: 80px;
}
.active {
  transition: all 0 ease-in;
}
</style>

<template>
  <div id="app">
    <headerbox></headerbox>
    <router-view />
  </div>
</template>
<script>
import headerbox from "./components/headerbox.vue";
export default {
  name: "app",
  components: {
    headerbox,
  },
};
</script>
<style lang="less"></style>

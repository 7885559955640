<template>
  <div class="join">
    <!-- top背景图-->
    <div class="swiper">
      <div class="title">
        <div class="h1">加入我们</div>
        <p>JOIN US</p>
      </div>
    </div>
    <!-- 业务简介 -->
    <div class="detail section">
      <div class="bigCard">
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img
              src="../assets/images/join/icons/icon1.png"
              alt="中医药文化讲师"
            />
          </div>
          <div class="right">
            <div class="h3">中医药文化讲师</div>
            <p>
              负责中小学生的中医药文化课程的讲授，根据实际授课情况对课程研发部门提出改进意见；负责基础的PPT课件制作以及与中医药文化相关的研学活动、节日活动方案设计。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img src="../assets/images/join/icons/icon2.png" alt="教材研发员" />
          </div>
          <div class="right">
            <div class="h3">教材研发员</div>
            <p>
              负责中医药传统文化普及课本读物的开发和编写，与团队合作完成教材、读物相关辅助材料的完善。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img
              src="../assets/images/join/icons/icon3.png"
              alt="新零售品牌运营"
            />
          </div>
          <div class="right">
            <div class="h3">新零售品牌运营主管</div>
            <p>
              负责市场分析和调研，品牌形象设计，制定品牌定位、品牌传播策略，提供品牌宣传支持，并对品牌策略的实施效果进行分析和评估和调整优化。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img src="../assets/images/join/icons/icon4.png" alt="文案策划" />
          </div>
          <div class="right">
            <div class="h3">文案策划</div>
            <p>
              负责撰写宣传资料和相关活动文案，项目推广执行涉及的相关软文及配图文案以及对外文化宣传及内部文化建设。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img src="../assets/images/join/icons/icon5.png" alt="摄像师" />
          </div>
          <div class="right">
            <div class="h3">摄像师</div>
            <p>
              负责重大活动、会议、人物采访、课程、企业宣传片等的拍摄创作以及剪辑和包装，参与拍摄前期分镜设计、氛围把控、灯光设计等工作。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img
              src="../assets/images/join/icons/icon6.png"
              alt="影视后期特效师"
            />
          </div>
          <div class="right">
            <div class="h3">影视后期特效师</div>
            <p>
              精通PS、AE、PR等软件；可熟练运用C4D等软件进行三维动画原创；可熟练使用AE各类常用插件以及C4D常用插件；负责剪辑，包装，栏目包装，3维特效，产品包装等内容。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img src="../assets/images/join/icons/icon7.png" alt="产品经理" />
          </div>
          <div class="right">
            <div class="h3">产品经理</div>
            <p>
              负责软件产品的功能制定、产品规划、设计，完成产品需求文档，并制作产品原型；协调研发、交互、UI、测试、运营人员完成产品从开发、测试，到发布等全部工作。
              具有优秀的沟通理解能力和团队合作能力，执行力强，善于组织协调推动项目发展。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img src="../assets/images/join/icons/icon8.png" alt="平面设计师" />
          </div>
          <div class="right">
            <div class="h3">平面设计师</div>
            <p>
              负责画册或教材类书籍装帧设计，公司宣传物料、网站页面、广告图片等设计。具有较高的设计制作效率，较强的学习能力和敬业精神，能根据要求快速完成相关设计任务；有良好的沟通、理解能力，具有工作协调能力和团队协作精神。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img
              src="../assets/images/join/icons/icon9.png"
              alt="flash设计师"
            />
          </div>
          <div class="right">
            <div class="h3">flash设计师</div>
            <p>
              熟悉Flash动画制作，负责根据分镜制作二维动画，独立完成整个动画的关键动作以及根据剧本或插图制作动画人设。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img src="../assets/images/join/icons/icon10.png" alt="3D建模" />
          </div>
          <div class="right">
            <div class="h3">3D建模</div>
            <p>
              负责游戏3D人物建模和场景建模，UV贴图绘制，熟练使用3dmax、Maya、Blender、Body
              Paint、Photoshop、Zbrush等必要软件，有较强的贴图能力，会骨骼绑定或3D动画者优先。能够与团队进行良好沟通，协作完成项目开发。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img src="../assets/images/join/icons/icon11.png" alt="unity开发" />
          </div>
          <div class="right">
            <div class="h3">unity开发</div>
            <p>
              精通 C #；熟悉多平台开发打包；熟练使用 unity
              内部组件，负责对不同精度模型进行骨骼绑定及权重优化，对动画数据进行导入与调试，unity引擎中3D写实人物角色的Pose设计，人物动画及表情动画制作等，及时跟进项目进展，可自行查找问题并进行优化。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img
              src="../assets/images/join/icons/icon12.png"
              alt="unity特效师"
            />
          </div>
          <div class="right">
            <div class="h3">unity特效师</div>
            <p>
              设计制作项目所需粒子、动画、交互等效果;设计制作游戏中所需场景、ui特效、光效等特殊效果;与项目组人员深入沟通，准确实现项目开发需求，保质保量完成特效制作。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img
              src="../assets/images/join/icons/icon13.png"
              alt="PHP开发工程师"
            />
          </div>
          <div class="right">
            <div class="h3">PHP开发工程师</div>
            <p>
              负责根据产品需求、系统需求，设计框架并组织参与代码编写；参与讨论产品流程和产品优化策略，参与项目中的编码工作。熟悉基于LAMP/LNMP
              WEB开发，熟悉php+MySql数据库开发。
            </p>
          </div>
        </div>
        <div class="common" data-aos="fade-up">
          <div class="leftCoin">
            <img
              src="../assets/images/join/icons/icon14.png"
              alt="软件测试工程师"
            />
          </div>
          <div class="right">
            <div class="h3">软件测试工程师</div>
            <p>
              负责根据需求文档及原型图编写测试用例；执行测试用例，跟踪定位和提交系统中的bug，协助开发修复bug；记录并跟进bug。熟悉对app等软件的功能，性能测试熟悉软件测试基本理论，测试流程。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作加盟 -->
    <div class="collaborate">
      <div class="title">
        <div class="h1">合作加盟</div>
        <p>COOPERATION AND FRANCHISE</p>
        <div class="content">
          《中医药文化中小学生读本》已成功走进北京、山东、安徽、天津、河北、河南、广西等地的中小学校。
        </div>
      </div>
      <div class="cardInput">
        <div class="form">
          <input
            class="one three"
            type="text"
            v-model="formInfor.name"
            placeholder="请输入您的姓名"
          />
          <input
            class="one three"
            v-model="formInfor.company"
            placeholder="请输入公司名称(选填)"
          />
          <input
            class="one"
            v-model="formInfor.tel"
            placeholder="请输入您的手机号码"
          />
          <input
            class="one"
            v-model="formInfor.email"
            placeholder="请输入您的邮箱"
          />
          <input
            class="two"
            v-model="formInfor.address"
            placeholder="请输入您的所在地（具体地址）"
          />
        </div>
        <div class="btn" @click="onSubmit">提交</div>
      </div>
    </div>
    <!-- 底部地图 -->
    <app-footer />
  </div>
</template>
<script>
import AppFooter from "@/components/appFooter.vue";
import { Toast } from "vant";
import { getEnter } from "@/api/http";
export default {
  components: {
    AppFooter,
  },
  data() {
    return {
      formInfor: {
        name: "",
        company: "",
        tel: "",
        email: "",
        address: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      const phoneRegex = /^1[3-9]\d{9}$/;
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      const isPhoneValid = phoneRegex.test(this.formInfor.tel);
      const isEmailValid = emailRegex.test(this.formInfor.email);
      console.log(isPhoneValid, isEmailValid, "---------");
      console.log("submit", this.formInfor);
      if (
        this.formInfor.name === "" ||
        this.formInfor.tel === "" ||
        this.formInfor.email === "" ||
        this.formInfor.address === ""
      ) {
        Toast({
          message: "请填写完整信息",
          className: "custom-toast",
        });
      } else if (!isPhoneValid) {
        Toast({
          message: "请填写正确号码",
          className: "custom-toast",
        });
      } else if (!isEmailValid) {
        Toast({
          message: "请填写正确邮箱",
          className: "custom-toast",
        });
      } else {
        const res = await getEnter(this.formInfor);
        if (res.code === 0) {
          Toast({
            message: "提交成功",
            className: "custom-toast",
          });
          this.formInfor = {
            name: "",
            tel: "",
            address: "",
            company: "",
            email: "",
          };
        } else if (res.code === -1) {
          Toast({
            message: res.msg,
            className: "custom-toast",
          });
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../assets/styles/join.less");
</style>
<style>
.custom-toast {
  font-size: 50px;
  padding: 40px 20px;
  white-space: nowrap;
}
</style>

import axios from 'axios'
// 创建axios实例
const service = axios.create({
  baseURL: 'https://www.gsjyvip.com'
  /*  timeout: 30000, */
})
// request 拦截器
service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)
// response 拦截器
service.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)
export default service

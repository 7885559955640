/* eslint-disable */
import Vue from 'vue'
import join from "../views/join.vue";
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
 {
  path : '/',
  name: 'Home',
  component: () => import('../views/index.vue'),
 },
 {
  path: "/product",
  name: "Product",
  component: () => import("../views/product.vue"),
 },
 {
   path: "/classcenter",
   name: "Classcenter",
   component: () => import("../views/classcenter.vue"),
 },
 {
   path:'/curriculumpilot',
   name:'Curriculumpilot',
   component:()=>import('../views/curriculumpilot.vue')
 },
 {
  path:'/classroom',
  name:'Classroom',
  component:()=>import('../views/classroom.vue')
 },
 {
  path:'/news',
  name:'News',
  component:()=>import('../views/news.vue')
 },
 {
  path: "/news/:id",
  name: "NewsDetail",
  component: () => import("../views/newsDetail.vue"),
 },
 {
  path:'/about',
  name:'About',
  component:()=>import('../views/about.vue')
 },
 {
  path:'/strategy',
  name:'Strategy',
  component:()=>import('../views/strategy.vue')
 },
 {
  path:'/join',
  name:'Join',
  component:join
 }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})
router.beforeEach((to, from, next) => {
  // 在每次路由切换之前，滚动到页面顶部
  window.scrollTo(0, 0);
  next();
});
export default router

